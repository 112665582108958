module.exports = [{
      plugin: require('/Users/boomauakim/Repositories/the-sixtynine-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"the-sixtynine-comunication","short_name":"the-sixtynine","start_url":"/","icon":"src/images/thesixtynine-icon.png"},
    },{
      plugin: require('/Users/boomauakim/Repositories/the-sixtynine-website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,500,900"]}},
    },{
      plugin: require('/Users/boomauakim/Repositories/the-sixtynine-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
